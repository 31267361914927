import React from "react";
import { Link } from "react-router-dom";
import { NOTICE, POLICY } from "../../configs/path";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="link">
        <Link to={POLICY}>プライバシーポリシー</Link>｜
        <Link to={NOTICE}>特定商取引法に基づく表記</Link>｜
        <a href="mailto:info.pairla@gmail.com?subject=Pairlaに関するお問い合わせ&body=お問い合わせ内容：（詳細を記載してください）">
          お問い合わせ
        </a>
      </div>
      <div className="link-mobile">
        <Link to={POLICY}>プライバシーポリシー</Link>
        <Link to={NOTICE}>特定商取引法に基づく表記</Link>
        <a href="mailto:info.pairla@gmail.com?subject=Pairlaに関するお問い合わせ&body=お問い合わせ内容：（詳細を記載してください）">
          お問い合わせ
        </a>
      </div>
      <div className="copyright">© pairla-video.com</div>
    </footer>
  );
}

export default Footer;
