import { useState } from 'react'
import { Helmet } from 'react-helmet'
import notice from '../assets/notice_text.png'
import noticeMobile from '../assets/notice_text_mobile.png'
import Footer from '../components/footer/Footer.components'
import Header from '../components/header/Header.components'
import Menu from '../components/menu/Menu.components'
import '../styles/Term.css'

function Notice() {
  const [visible, setVisible] = useState(false)
  return (
    <div>
      <Helmet>
        <title>特定商取引法に基づく表記</title>
      </Helmet>
      <Header />
      <Menu visible={visible} setVisible={setVisible} />
      <div className="term-container">
        <h1>特定商取引法に基づく表記</h1>
        <div className="term-content-wrapper">
          <img className="notice-content" src={notice} alt="" />
          <img className="notice-content-mobile" src={noticeMobile} alt="" />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Notice
