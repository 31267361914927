import React, { useEffect, useRef, useState } from "react";
import "../styles/HomeLayout.css";
import googlePlayImg from "../assets/image_badge_android.png";
import deviceVideoImg from "../assets/image_device_video.png";
import pairlaLogo from "../assets/image_logo.png";
import iconGroup1 from "../assets/group_2.png";
import iconGroup2 from "../assets/group_3.png";
import iconGroup3 from "../assets/group_4.png";
import iconGroup6 from "../assets/group_6.png";
import appIcon from "../assets/image_app_icon.png";
import deviceChatImg from "../assets/image_device_chat.png";
import Footer from "../components/footer/Footer.components";
import Menu from "../components/menu/Menu.components";
import { MENU } from "../configs/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HOME } from "../configs/path";

function HomeLayout({children} : any) {
  const [visible, setVisible] = useState(false);
  const [display, setDisplay] = useState(true);
  const location = useLocation();
  const topRef = useRef<any>();
  const aboutRef = useRef<any>();
  const moreRef = useRef<any>();
  const linkRef = useRef<any>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [menu, setMenu] = useState<any>(MENU);

  const handleScrollDown = () => {
    linkRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (location.hash === "#top") {
      topRef.current.scrollIntoView();
      setMenu(MENU);
    } else if (location.hash === "#about") {
      aboutRef.current.scrollIntoView();
      let newMenu = [...MENU];
      newMenu[0] = { ...newMenu[0], active: false };
      newMenu[1] = { ...newMenu[1], active: true };
      setMenu(newMenu);
    } else if (location.hash === "#more") {
      moreRef.current.scrollIntoView();
      let newMenu = [...MENU];
      newMenu[0] = { ...newMenu[0], active: false };
      newMenu[2] = { ...newMenu[2], active: true };
      setMenu(newMenu);
    } else if (location.hash === "#link") {
      linkRef.current.scrollIntoView();
    } else window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    if (scrollPosition > 3474) {
      setDisplay(false);
    } else setDisplay(true);
  }, [scrollPosition]);

  return (
    <div className="home-wrapper">
      {children}
      <div id="top" className="top-wrapper" ref={topRef}>
        <div className="menu-top">
          <ul className="menu">
            {menu.map((item: any) => {
              return (
                <li
                  key={item.id}
                  className={(item.active ? "active" : "") + " list-item"}
                >
                  <a href={item.path}>{item.text}</a>
                </li>
              );
            })}
            <li className="logo-google">
              <img src={googlePlayImg} alt="" width={156} height={50} />
            </li>
          </ul>
        </div>

        <Menu visible={visible} setVisible={setVisible} />

        <div className="top-content row">
          <div className="pairla-content col-sm-12 col-lg-6">
            <img className="logo" src={pairlaLogo} alt="" />
            <div className="intro-text">
              社会人のためのメンタルヘルスケアサポートアプリ
            </div>
            <div className="mobile-intro-text">
              社会人のための
              <br />
              メンタルヘルスケアサポートアプリ
            </div>
            <div className="pairla-list-content">
              <div className="item-wrapper">
                <img src={iconGroup1} alt="" />
                <p>仕事の悩み相談</p>
              </div>
              <div className="item-wrapper">
                <img src={iconGroup2} alt="" />
                <p>体調の悩み相談</p>
              </div>
              <div className="item-wrapper">
                <img src={iconGroup3} alt="" />
                <p>ストレス解消</p>
              </div>
            </div>
          </div>

          <img
            className="image-container col-sm-12 col-lg-6"
            src={deviceVideoImg}
            alt=""
          />
        </div>
      </div>

      <div
        id="about"
        className="about-wrapper"
        ref={aboutRef}
        onScroll={() => navigate({ hash: "#about" })}
      >
        <h2>Pairla（ペアラ）とは</h2>
        <p>
          日々に疲れた社会人のためのメンタルヘルスをサポートするアプリです。
          <br />
          <br />
          仕事の悩みや体調の悩み、日頃のイライラは
          <br />
          誰かに話をしてストレスを解消しましょう。
        </p>

        <div className="about-content">
          <img className="app-icon" src={appIcon} alt="" />
          <p className="intro-app-text">
            Androidアプリ「Pairla（ペアラ）」は
            <br />
            Google Playからダウンロードできます。
          </p>
          <hr className="divider" />
          <p className="notice-text">今なら15分お試しポイントプレゼント中!!</p>
          <img src={googlePlayImg} alt="" width={156} height={50} />
        </div>
      </div>

      <div id="more" className="more-wrapper" ref={moreRef}>
        <h2 className="section-title">Pairla（ペアラ）の機能</h2>
        <div className="more-content reverse row">
          <span className="col-12 col-md-6">
            <h2>メッセージで相談する</h2>
            <p>
              日々の
              <Link to={HOME} className="hyperlink-text">
                生活スタイルや趣味が合う人にメッセージを送って相談
              </Link>
              してみましょう。
              <br />
              <br />
              普段あまり話さないタイプの相手から意見をもらったり、身近な人には話せない話でも気軽に相談に乗ってもらえるので悩みが解消されるかもしれません。
            </p>
          </span>
          <img
            className="image-container col-12 col-md-6"
            src={deviceChatImg}
            alt=""
          />
        </div>
        <div className="more-content row">
          <span className="col-12 col-md-6">
            <h2>音声・ビデオ通話で相談する</h2>
            <p>
              ペアラではすべての方に音声・ビデオ通話機能で相談することができます。
              <br />
              <br />
              特に相手の顔を見えるビデオ通話は、より親身になって相談に乗ってもらえるので様々な悩みやストレスの解消になります。
              <br />
              <br />
              落ち込んだ日はペアラでストレスを解消しましょう。
            </p>
          </span>
          <img
            className="image-container col-12 col-md-6"
            src={deviceVideoImg}
            alt=""
          />
        </div>
      </div>
      <div id="link" ref={linkRef} className="term-wrapper">
        <div className="about-content">
          <img className="app-icon" src={appIcon} alt="" />
          <p className="intro-app-text">
            Androidアプリ「Pairla（ペアラ）」は
            <br />
            Google Playからダウンロードできます。
          </p>
          <hr className="divider" />
          <p className="notice-text">今なら15分お試しポイントプレゼント中!!</p>
          <img src={googlePlayImg} alt="" width={156} height={50} />
        </div>
      </div>
      {display && (
        <img
          className="arrow-down"
          src={iconGroup6}
          alt=""
          onClick={handleScrollDown}
        />
      )}
      <Footer />
    </div>
  );
}

export default HomeLayout;
