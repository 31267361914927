import { createBrowserRouter, RouteObject } from "react-router-dom";
import { HOME, NOTICE, POLICY, TERM } from "./configs/path";
import Home from "./pages/home";
import Notice from "./pages/Notice";
import Policy from "./pages/Policy";
import Term from "./pages/Term";

const routes: RouteObject[] = [
  {
    path: HOME,
    element: <Home />,
  },
  {
    path: TERM,
    element: <Term />,
  },
  {
    path: NOTICE,
    element: <Notice />,
  },
  {
    path: POLICY,
    element: <Policy />,
  },
];

const router = createBrowserRouter(routes);

export default router;
